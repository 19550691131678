<template>
	<div class="gg-container">
		<div class="search-container">
			<div class="search-container-fn-input">
				<label>关键字：</label>
				<el-input size="small" placeholder="输入姓名或手机号查找" prefix-icon="el-icon-search"
									v-model="searchParams.keywords" clearable @change="getList">
				</el-input>
			</div>
			<div class="search-container-fn-input" style="margin-left: auto;">
				<el-button size="mini" type="success"
									 style="padding: 6px 7px 7px 8px;background-color: #FCC810; border-color: #FCC810"
									 @click="handlerDownloadTemplate">
					<i class="el-icon-download" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px; ">下载模板</span>
				</el-button>
			</div>
			<div class="search-container-fn-input" style="margin-left: 10px">
				<upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload"/>
			</div>
			<div class="search-container-fn-input" style="margin-left: 10px">
				<el-button size="mini" type="success" style="padding: 6px 7px 7px 8px" @click="handlerExportExcel">
					<i class="iconfont icon-liuzhuanjianguan" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">导出</span>
				</el-button>
			</div>
		</div>
		<el-table
			:data="tableData"
			style="width: 100%">
			<el-table-column
				prop="date"
				label="日期"
				width="180">
			</el-table-column>
			<el-table-column
				prop="name"
				label="姓名"
				width="180">
			</el-table-column>
			<el-table-column
				prop="address"
				label="地址">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import UploadExcelComponent from '@/components/UploadExcel'
import {parseTime} from '@/utils'
export default {
  name: "ImportExportDown",
  components: {
    UploadExcelComponent
  },
  data () {
    return {
      searchParams: {},
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    }
  },
  methods: {
    getList () {
    },
    // 本地下载模板, 模板存放在 /public/file 下
    handlerDownloadTemplate () {
      console.log(this.$refs.download);
      let a = document.createElement('a');
      a.href = `/file/PatientRecords.xlsx`;
      a.download = '档案管理模板.xlsx';
      a.click();
    },
    // excel上传前
    beforeUpload (file) {
      console.log(file)
      const isLt1M = file.size / 1024 / 1024 < 3
      console.log(isLt1M)
      if (isLt1M) {
        return true
      }
      this.$message({
        message: '请传入excel大小不要超过3M.',
        type: 'warning'
      })
      return false
    },
    // excel成功的结果 result excel->obj
    handleSuccess ({ results, header }) {
      console.log(results)
      let data = {
        file: results
      }
      console.log(data)
    },
    handlerExportExcel () {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['日期', '姓名', '地址'] // table表的 label
        const filterVal = ['date', 'name', 'address'] // table表 prop
        const list = this.tableData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '测试导出',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    },
  }
}
</script>

<style scoped lang="scss">

</style>
